import React, { useContext } from "react";
import styled, { css } from "styled-components";
import Container from "components/Container";

import AppContext from "contexts/AppContext";

import { ReactComponent as SvgLogo } from "assets/icons/logo_symbol.svg";
import { ReactComponent as SvgInstagram } from "assets/icons/icon_instagram.svg";
import { ReactComponent as SvgWhatsapp } from "assets/icons/whatsapp-outline.svg";

const Wrap = styled.section`
  width: 100%;
  background: ${(props) => props.theme.color.dark};
  color: #fff;
  display: flex;
  padding: 20px 0;
  background-size: cover;
  position: relative;
`;
const Logo = styled(SvgLogo)`
  height: 30px;
  fill: #fff;
`;
const Copy = styled.div`
  font-size: 12px;
  text-align: center;
  margin: 0;
  opacity: 0.3;
  line-height: 30px;
`;
const Nav = styled.nav``;
const Link = styled.a`
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin: 0 20px 0 0;

  &:last-child {
    margin-right: 0;
  }
`;
const IconsCss = css`
  height: 20px;
  fill: #fff;
`;
const IconInstagram = styled(SvgInstagram)`
  ${IconsCss}
`;
const IconWhatsapp = styled(SvgWhatsapp)`
  ${IconsCss}
`;

const Footer = () => {
  const { config } = useContext(AppContext);
  return (
    <Wrap>
      <Container>
        <Logo />
        <Copy>&copy; {new Date().getFullYear()} Fusion</Copy>
        <Nav>
          <Link
            href="https://www.instagram.com/fusion.clinic/"
            title="Instagram"
            target="_blank"
          >
            <IconInstagram />
          </Link>
          <Link
            href={`//wa.me/${config.whatsapp}`}
            title="Facebook"
            target="_blank"
          >
            <IconWhatsapp />
          </Link>
        </Nav>
      </Container>
    </Wrap>
  );
};

export default Footer;
