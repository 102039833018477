import React, { useContext } from "react";
import styled from "styled-components";
import Device from "device";
import { Modal } from "components/Modal/Modal";
import { ReactComponent as SvgMenu } from "assets/icons/icon_menu.svg";
import AppContext from "contexts/AppContext";

import ym from "react-yandex-metrika";

const Body = styled.section`
  padding: 30px 20px 20px 20px;
  background-image: url(i/pattern_gray.svg);
  background-color: #fff;
  background-size: 120px auto;
  background-repeat: repeat;
  display: flex;

  @media ${Device.laptop} {
    padding: 50px;
  }
`;
const Close = styled.button`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
  cursor: pointer;
  transition: all 0.4s;
  user-select: none;
  background: #fff;
  border-radius: 100px;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
  pointer-events: all;

  @media ${Device.laptop} {
    top: 20px;
    right: 20px;
  }
`;
const ReviewWrap = styled.div`
  width: 100%;
  height: 100%;
  min-height: 1300px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Review = styled.iframe`
  position: relative;
  max-width: 100%;
  height: 100%;
  width: 760px;
  min-height: 100vh;
  z-index: 2;
  margin: 0 auto;
  box-shadow: 0 20px 5px -15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;
const IconMenu = styled(SvgMenu)`
  width: 38px;
  height: 38px;
  stroke: #222;
  transform: translate(-1px, -1px);
`;

const ReviewModal = (props) => {
  const { setModal } = useContext(AppContext);
  // ym("reachGoal", "open3dTour");

  return (
    <Modal textAlign="center" modalWidth="max" padding="0">
      <Body>
        <ReviewWrap>
          <Review
            src="https://yandex.ru/maps-reviews-widget/55607294817?comments&wmode=transparent"
            frameborder="0"
            allowFullScreen="true"
            wmode="Opaque"
          ></Review>
        </ReviewWrap>
      </Body>
      <Close onClick={() => setModal(null)} className="cross">
        <IconMenu />
      </Close>
    </Modal>
  );
};

export default ReviewModal;
