const Theme = {
  color: {
    dark: "#163D5C",
    gray: "#A9B0B7",
    cyan: "#5fa2d0",
    cyan_hover: "#346e96",
    red: "#e73c5c",
    red_hover: "#bf2d48",
    green: "#8bc34a",
  },
  width: {
    container: "1170px",
  },
  fonts: {
    digits:
      "Consolas, Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace, sans-serif;",
  },
};

export default Theme;
