import React, { useContext } from "react";
import styled from "styled-components";
import "animate.css";

import Topline from "components/Topline";
import Sidebar from "components/Sidebar";
import Header from "components/Header";
import Service from "components/Service";
import About from "components/About";
import Team from "components/Team";
import Portfolio from "components/Portfolio";
import Contacts from "components/Contacts";
import Footer from "components/Footer";

import AppContext from "contexts/AppContext";

const Main = styled.main``;
const SidebarBackDrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99;
  cursor: pointer;
  backdrop-filter: blur(5px);
`;

function Homepage() {
  const { menuOpen, setMenuOpen, dev } = useContext(AppContext);

  return (
    <Main>
      <Topline />
      <Header />
      <Service />
      <About />
      <Team />
      <Portfolio />
      <Contacts />
      <Footer />
      <Sidebar />
      {menuOpen ? <SidebarBackDrop onClick={() => setMenuOpen(false)} /> : null}
    </Main>
  );
}

export default Homepage;
