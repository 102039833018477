const photos = [
  {
    url: "/i/clinic/clinic-11.jpg",
    webp: "/i/clinic/clinic-11.webp",
  },
  {
    url: "/i/clinic/clinic-13.jpg",
    webp: "/i/clinic/clinic-13.webp",
  },
  {
    url: "/i/clinic/clinic-14.jpg",
    webp: "/i/clinic/clinic-14.webp",
  },
  {
    url: "/i/clinic/clinic-15.jpg",
    webp: "/i/clinic/clinic-15.webp",
  },
  {
    url: "/i/clinic/clinic-10.jpg",
    webp: "/i/clinic/clinic-10.webp",
  },
  {
    url: "/i/clinic/clinic-1.jpg",
    webp: "/i/clinic/clinic-1.webp",
  },
  {
    url: "/i/clinic/clinic-2.jpg",
    webp: "/i/clinic/clinic-2.webp",
  },
  {
    url: "/i/clinic/clinic-7.jpg",
    webp: "/i/clinic/clinic-7.webp",
  },
  {
    url: "/i/clinic/clinic-3.jpg",
    webp: "/i/clinic/clinic-3.webp",
  },
  {
    url: "/i/clinic/clinic-4.jpg",
    webp: "/i/clinic/clinic-4.webp",
  },
  {
    url: "/i/clinic/clinic-5.jpg",
    webp: "/i/clinic/clinic-5.webp",
  },
  {
    url: "/i/clinic/clinic-6.jpg",
    webp: "/i/clinic/clinic-6.webp",
  },
  {
    url: "/i/clinic/clinic-9.jpg",
    webp: "/i/clinic/clinic-9.webp",
  },
  {
    url: "/i/videoposter.png",
    webp: "/i/videoposter.webp",
    video: "/video/fusion.mp4",
  },
];
export default photos;
