import React from "react";
import styled from "styled-components";

import Topline from "components/Topline";

const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  min-height: 100%;
`;
const ThankYouText = styled.div`
  padding: 40px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  background: ${(props) => props.theme.color.cyan};
  color: #fff;
`;

function ThankYouPage() {
  setTimeout(() => (window.location = "/"), 10000); // go home
  return (
    <Main>
      <Topline />
      <ThankYouText>Спасибо, ваша заявка отправлена!</ThankYouText>
    </Main>
  );
}

export default ThankYouPage;
