import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import Device from "device";
// import Banner from "components/Banner";
import Button from "components/Button";

import AppContext from "contexts/AppContext";
import FormModal from "components/Modal/FormModal";

// import { ReactComponent as SvgLogo } from "assets/icons/logo_symbol.svg";
import { ReactComponent as SvgChat } from "assets/icons/chat.svg";

const Buttons = styled.div`
  position: absolute;
  left: 0;
  bottom: -15px;
  right: 0;
  width: auto;
  z-index: 9;
  display: flex;
  align-items: stretch;
  justify-content: center;

  @media ${Device.laptop} {
    bottom: 90px;
    left: 20px;
    top: auto;
    right: 20px;
    justify-content: flex-start;
  }
  @media ${Device.laptopL} {
    flex-direction: row;
  }
`;
const Wrap = styled.section`
  width: 100%;
  position: relative;
  background: #fff;
  z-index: 5;

  &:before {
    @media ${Device.tablet} {
      content: "";
      position: absolute;
      left: 0;
      width: 500px;
      bottom: 0;
      height: 50px;
      background: rgb(238, 239, 244);
      mask-image: url("/i/section_arrow2.png");
      mask-size: auto 50px;
      mask-repeat: no-repeat;
      mask-position: 0 0;
    }
  }
  &:after {
    @media ${Device.tablet} {
      content: "";
      position: absolute;
      left: 499px;
      right: 0;
      bottom: 0;
      height: 50px;
      background: rgb(238, 239, 244);
    }
  }
`;
const DiscountButton = styled(Button)`
  display: flex;
  align-items: center;
  margin: 0;
  background: ${(props) => props.theme.color.red};
  color: #fff;
  line-height: normal;
  text-transform: none;
  align-items: center;
  padding: 0 20px 0 0;

  @media ${Device.tablet} {
    line-height: 20px;
    margin: 0 15px;
    padding: 0 20px 0 0;
  }
  @media ${Device.laptopL} {
    margin-left: 15px;
  }

  span {
    text-align: left;
  }

  &:hover {
    color: #fff;
    background: ${(props) => props.theme.color.red_hover};
  }
`;
const IconChat = styled(SvgChat)`
  width: 60px;
  height: 60px;
  padding: 12px;
  margin: 0;
  stroke: #fff;
`;
const Video = styled.video`
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  min-height: 350px;

  &::-webkit-media-controls {
    display: none !important;
  }

  @media ${Device.tablet} {
    padding: 0;
    min-height: 500px;
  }
  @media ${Device.laptop} {
    height: auto;
    min-height: 100vh;
    min-height: calc(100vh - 30px);
    max-height: calc(100vh - 30px);
  }
`;

const Header = () => {
  const { section, setModal } = useContext(AppContext);
  const videoRef = useRef();
  const player = videoRef.current;

  useEffect(() => {
    if (player) {
      player.muted = true;
      player.setAttribute("muted", ""); // leave no stones unturned :)
    }
  }, [player]);

  return (
    <Wrap id="top" className={section === "top" ? "active" : null}>
      <Video
        ref={videoRef}
        controls={false}
        playsInline
        autoPlay={true}
        loop
        muted
        poster="/video/poster.jpg"
      >
        <source src="/video/background.mov" type="video/mp4" />
        <source src="/video/background.mp4" type="video/mp4" />
        <source src="/video/background.ogv" type="video/ogv" />
        <source src="/video/background.webm" type="video/webm"></source>
        Your browser does not support the video tag.
      </Video>

      <Buttons>
        <DiscountButton
          className="button"
          onClick={() =>
            setModal(
              <FormModal
                name="Бесплатная консультация стоматолога"
                type="service"
              />
            )
          }
        >
          <IconChat />
          <span>
            Бесплатная консультация
            <strong>
              <br />
              стоматолога
            </strong>
          </span>
        </DiscountButton>
      </Buttons>
    </Wrap>
  );
};

export default Header;
