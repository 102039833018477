import React, { useContext } from "react";
import styled from "styled-components";
import Device from "device";
import Container from "components/Container";

import ScrollAnimation from "react-animate-on-scroll";
import parse from "html-react-parser";
import ContentModal from "components/Modal/ContentModal";

import { isWebpSupported } from "react-image-webp/dist/utils";

// import { ReactComponent as SvgSectionArrow } from "assets/icons/section_arrow.svg";
import { ReactComponent as SvgFullscreen } from "assets/icons/icon-fullscreen.svg";

import { service } from "content/service";

import AppContext from "contexts/AppContext";

const Wrap = styled.section`
  width: 100%;
  background: rgb(238, 239, 244);
  display: flex;
  align-items: center;
  padding: 40px 0;
  position: relative;
  color: #fff;
  overflow: hidden;
  flex-wrap: wrap;
  z-index: 3;

  @media ${Device.laptopM} {
    min-height: 900px;
    padding: 100px 0;
    margin-top: -50px;
  }
`;
const Title = styled.h2`
  width: 100%;
  color: ${(props) => props.theme.color.gray};
  font-weight: bold;
`;
const ServiceList = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
`;
const ServiceItem = styled.div`
  padding: 0;
  border-radius: 4px;
  margin: 10px;
  background: #fff;
  width: 100%;
  color: ${(props) => props.theme.color.dark};
  position: relative;
  transition: all 1s 0.1s;
  overflow: hidden;
  border: none;
  box-shadow: 0 20px 5px -15px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  @media ${Device.tablet} {
    max-width: 45%;
  }
  @media ${Device.laptop} {
    max-width: 23%;
  }

  &:hover {
    z-index: 3;
    opacity: 1;

    h3 {
      &:after {
        background: #ccc;
      }
    }

    .readmore {
      opacity: 1;
      visibility: visible;
    }
  }
`;
const ServiceCover = styled.div`
  width: 100%;
  height: 200px;
  background-color: #dee0ea;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0.1);
  position: relative;
`;
const ServiceItemBody = styled.div`
  padding: 30px;
`;
const ServiceTitle = styled.h3`
  margin: 0 0 10px 0;
  font-size: 18px;
`;
const Desc = styled.div`
  color: #222;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  li {
    margin: 0 0 5px 0;
    padding: 0 0 0 20px;
    font-size: 14px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      width: 5px;
      height: 5px;
      background: none;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const More = styled.div`
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 10px;
  line-height: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.4s;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  span {
    width: 100%;
    text-align: center;
  }
`;
const FullscreenIcon = styled(SvgFullscreen)`
  width: 18px;
  height: 18px;
  fill: #fff;
  margin: 0 0 10px 0;
`;

const Service = () => {
  const { section, setModal } = useContext(AppContext);
  return (
    <Wrap id="service" className={section === "service" ? "active" : null}>
      <Container>
        <Title>Услуги и цены</Title>
      </Container>
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <ServiceList>
          {service.map((item, index) => (
            <ServiceItem
              key={index}
              onClick={() =>
                setModal(
                  <ContentModal
                    cover={isWebpSupported() ? item.webpbig : item.imgbig}
                    title={item.name}
                    content={item.more}
                    price={item.price ? item.price : false}
                    type="service"
                  />
                )
              }
            >
              <ServiceCover
                style={{
                  backgroundImage: `url(${
                    isWebpSupported() ? item.webp : item.img
                  })`,
                }}
              >
                {item.more ? (
                  <More className="readmore">
                    <FullscreenIcon />
                    <span>Подробнее</span>
                  </More>
                ) : null}
              </ServiceCover>
              <ServiceItemBody>
                <ServiceTitle>{item.name}</ServiceTitle>
                {item.desc ? <Desc>{parse(item.desc)}</Desc> : null}
              </ServiceItemBody>
            </ServiceItem>
          ))}
        </ServiceList>
      </ScrollAnimation>
    </Wrap>
  );
};

export default Service;
