import React, { useState, useContext } from "react";
import styled from "styled-components";
import Device from "device";
import MenuItems from "components/MenuItems";
import { ReactComponent as SvgLogo } from "assets/icons/logo_horizont.svg";
import { ReactComponent as SvgMenu } from "assets/icons/icon_menu.svg";

import AppContext from "contexts/AppContext";

const Wrap = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.4s;
  z-index: 999;

  &.ondown {
    .menutoggle {
      transform: translateY(0);
      opacity: 1;
    }
    .menu_logo {
      @media ${Device.laptop} {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;
const MenuToggle = styled.button`
  position: fixed;
  z-index: 999;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 1;
  cursor: pointer;
  transition: all 0.4s;
  user-select: none;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100px;

  @media ${Device.laptop} {
    opacity: 0;
    top: 18px;
    right: 20px;
    transform: translateY(-40px);
  }

  &:focus {
    outline: none;
  }
`;
const IconMenu = styled(SvgMenu)`
  width: 40px;
  height: 40px;
  stroke: #222;
  transform: translate(-1px, -1px);

  @media ${Device.laptop} {
    width: 50px;
    height: 50px;
  }
`;
const MenuLogoText = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;
  transform: scale(0);
  transition: all 0.2s 0.4s;

  @media ${Device.laptop} {
    opacity: 0;
    visibility: hidden;
  }
`;
const IconLogoText = styled(SvgLogo)`
  height: 21px;
  width: 88px;
  fill: #000;
  margin: 0 5px 0 0;
  fill: ${(props) => props.theme.color.dark};
  transform: translateY(-3px);

  @media ${Device.laptop} {
    height: 30px;
  }
`;

const SideBar = styled.nav`
  background: #fff;
  position: fixed;
  width: 300px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 60px 40px;
  transition: all 0.4s;
  transform: translateX(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  visibility: hidden;

  a,
  button {
    width: 100%;
    margin: 15px 0;

    &:after {
      bottom: -10px;
      width: 30px;
    }
  }

  &.open {
    transform: translateX(0);
    visibility: visible;

    .menu_logo {
      transform: scale(1);
    }
  }
`;

const Sidebar = () => {
  const [onTop, setOnTop] = useState(false);
  const { menuOpen, setMenuOpen } = useContext(AppContext);

  function handleSetMenuToggle() {
    setMenuOpen(menuOpen ? false : true);
  }

  window.addEventListener("scroll", function (event) {
    var scroll = this.scrollY;

    if (scroll > window.innerHeight - 110) {
      setOnTop(true);
    } else {
      setOnTop(false);
    }
  });

  return (
    <Wrap className={onTop ? "ondown" : "ontop"}>
      <SideBar className={menuOpen ? "menu open" : "menu"}>
        <MenuLogoText className="menu_logo">
          <IconLogoText />
        </MenuLogoText>
        <MenuItems />
      </SideBar>
      <MenuToggle
        onClick={() => handleSetMenuToggle()}
        className={menuOpen ? "menutoggle cross" : "menutoggle"}
      >
        <IconMenu />
      </MenuToggle>
    </Wrap>
  );
};

export default Sidebar;
