import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Device from "device";
import Button from "components/Button";

import { Modal } from "components/Modal/Modal";
import FormModal from "components/Modal/FormModal";

import AppContext from "contexts/AppContext";

import ym from "react-yandex-metrika";

import { ReactComponent as SvgLogo } from "assets/icons/logo_symbol.svg";
import { ReactComponent as SvgMenu } from "assets/icons/icon_menu.svg";

const Header = styled.header`
  display: flex;
  padding: 20px;
  transform: translate(0, 100%);
  opacity: 0;
  transition: all 0.2s 0s;
  box-shadow: 0 10px 15px 15px rgba(255, 255, 255, 1);
  z-index: 2;
  background: #fff;

  @media ${Device.laptop} {
    padding: 80px 100px 0 120px;
  }

  &.visible {
    opacity: 1;
    transform: translate(0, 0);
  }
`;
const Body = styled.section`
  padding: 20px;
  opacity: 0;
  transition: all 0.2s 0.4s;
  transform: scale(0.9);
  max-height: 100%;
  overflow: auto;

  @media ${Device.laptop} {
    padding: 40px 100px 80px 120px;
  }

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
`;
const Logo = styled(SvgLogo)`
  width: 30px;
  min-width: 30px;
  height: 40px;
  fill: ${(props) => props.theme.color.dark};
  transform: translate(-100%, 0);
  pointer-events: none;
  opacity: 0;
  margin: 0 15px 0 0;
  transition: all 0.2s 0.2s;
  position: relative;
  top: -5px;

  @media ${Device.laptop} {
    width: 60px;
    min-width: 60px;
    height: 120px;
    margin: 0 20px 0 0;
    top: -20px;
  }

  &.visible {
    opacity: 1;
    transform: translate(0, 0);
  }
`;
const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  line-height: normal;
  color: ${(props) => props.theme.color.dark};
  display: inline-block;

  @media ${Device.laptop} {
    font-size: 36px;
  }
`;
const Closebutton = styled.button`
  border: none;
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 0;
  margin: 0;
  z-index: 999;
  opacity: 0;
  background: rgba(255, 255, 255, 0.5);
  color: #222;
  border-radius: 100px;

  &.visible {
    opacity: 1;
  }
  &:hover {
    background: #fff;
  }

  @media ${Device.laptop} {
    top: 40px;
    right: 40px;
  }
`;
const IconMenu = styled(SvgMenu)`
  width: 30px;
  height: 30px;
  stroke: currentColor;
  cursor: pointer;
  transition: all 0.1s;
  margin: -1px 0 0 -2px;

  @media ${Device.laptop} {
    width: 50px;
    height: 50px;
    margin: 0;
  }
`;
const Cover = styled.div`
  width: 100%;
  height: 300px;
  min-height: 300px;
  background-color: #eee;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 3;

  @media ${Device.laptop} {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    width: auto;
    height: auto;
  }
`;
const Btn = styled(Button)`
  width: auto;
  margin: 30px 0 0 0;
  text-align: center;
`;
const ContactsWrap = styled.div`
  padding: 20px;
  margin: 40px 0 0 0;
  background: #f7f7f7;
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
`;

const PriceHeader = styled.div`
  display: flex;
`;
const PriceHeaderTitle = styled.h3`
  font-size: 24px;
  margin: 0 0 10px 0;
  flex: 1;
`;
const PriceTable = styled.section`
  width: 100%;
  margin: 30px 0;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  padding: 30px 0;
`;
const PriceRow = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin: 0;
  width: 100%;
  transition: all 0.2s;

  &.header {
    padding: 30px 0 10px 0;
    font-size: 24px;
    font-weight: bold;

    &:hover {
      background: none;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.01);
  }
`;
const PriceTitle = styled.div`
  flex: 1;
  padding: 0 20px 0 0;
  cursor: default;
`;
const PriceCost = styled.div`
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  font-weight: bold;
  text-align: right;
  color: ${(props) => props.theme.color.dark};
  font-family: ${(props) => props.theme.fonts.digits};

  &.rub {
    font-family: "Helvetica Neue", "Clear Sans", sans-serif;
  }
`;

const Contacts = (props) => {
  const { config, setModal } = useContext(AppContext);
  let data = props.props;

  return (
    <ContactsWrap>
      <p>
        <strong>Адрес:</strong> Московский проспект 73, корпус 5
      </p>
      <p>
        <strong>Режим работы:</strong> 09.00 - 21.00 (ежедневно)
      </p>
      <p>
        <strong>Телефон:</strong>{" "}
        <a href={`tel:${config.code}${config.phone}`}>
          {config.code}&nbsp;{config.phone}
        </a>
      </p>
      {props.type ? console.log(props) : null}

      <Btn
        className="min invert"
        onClick={() =>
          setModal(<FormModal name={data.title} type={data.type} />)
        }
      >
        {
          {
            service: "Запись на прием",
            person: "Запись к специалисту",
          }[data.type]
        }
      </Btn>
    </ContactsWrap>
  );
};
const ContentModal = (props) => {
  const { setModal } = useContext(AppContext);
  const [isDone, setIsDone] = useState(false);
  const [isFade, setIsFade] = useState(false);

  function handleClosemodal() {
    setIsFade(true);
    setTimeout(() => setModal(null), 800);
  }

  useEffect(() => {
    setTimeout(() => setIsDone(true), 400);

    if (props.type === "person") {
      ym("reachGoal", "openPerson", { name: props.title });
    }
    if (props.type === "service") {
      ym("reachGoal", "openService", { title: props.title });
    }
  }, []);

  return (
    <Modal
      textAlign="center"
      modalWidth={
        "max" +
        " " +
        (isFade ? "fade" : null) +
        " " +
        (props.cover ? "cover" : null)
      }
      padding="0"
    >
      <Closebutton
        className={isDone ? "visible cross" : "hidden"}
        onClick={handleClosemodal}
      >
        <IconMenu />
      </Closebutton>

      {props.cover ? (
        <Cover style={{ backgroundImage: `url(${props.cover})` }} />
      ) : null}
      {props.title ? (
        <Header className={isDone ? "visible" : "hidden"}>
          <Logo className={isDone ? "visible" : "hidden"} />
          <Title>
            <span>{props.title}</span>
          </Title>
        </Header>
      ) : null}
      {props.content ? (
        <Body className={isDone ? "visible" : "hidden"}>
          {props.about ? props.about : null}
          {props.content}
          {props.price ? (
            <PriceTable>
              <PriceHeader>
                <PriceHeaderTitle>Цены</PriceHeaderTitle>
                <PriceCost className="rub">₽</PriceCost>
              </PriceHeader>
              {props.price.map((item, i) => (
                <PriceRow key={i} className={item.header ? "header" : null}>
                  <PriceTitle>{item.title}</PriceTitle>
                  {item.cost ? <PriceCost>{item.cost}</PriceCost> : null}
                </PriceRow>
              ))}
            </PriceTable>
          ) : null}
          <Contacts props={props} />
        </Body>
      ) : (
        <Body className={isDone ? "visible" : "hidden"}>
          <p>
            <strong>Раздел в разработке..</strong>
          </p>
        </Body>
      )}
    </Modal>
  );
};

export default ContentModal;
