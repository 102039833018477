import { useContext } from "react";
import styled from "styled-components";
import Device from "device";
import { ReactComponent as SvgWhatsapp } from "assets/icons/whatsapp.svg";
import AppContext from "contexts/AppContext";

const Whatsapp = styled.a`
  text-decoration: none;
  margin: 0 0 0 20px;
  padding: 0;
  cursor: pointer;
  position: relative;
  user-select: none;
  border: none;
  background: none;
  text-align: left;
  color: ${(props) => props.theme.color.dark};
  font-size: 14px;

  display: flex;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  line-height: 13px;
  margin-left: 40px;

  @media ${Device.laptop} {
    line-height: 30px;
  }
  @media ${Device.laptop} {
    font-size: 14px;
  }
  @media ${Device.laptopM} {
    font-size: 16px;
  }

  span {
    line-height: 14px;
  }

  &:after {
    display: none;
  }
`;
const IconWhatsapp = styled(SvgWhatsapp)`
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
`;

const WhatsApp = () => {
  const { config } = useContext(AppContext);
  return (
    <Whatsapp href={`//wa.me/${config.whatsapp}`} target="_blank">
      <IconWhatsapp />
      <span>
        Напишите нам <br />
        в&nbsp;Whatsapp
      </span>
    </Whatsapp>
  );
};

export default WhatsApp;
