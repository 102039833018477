const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1025px",
  laptopM: "1200px",
  laptopL: "1440px",
  desktop: "2560px",
};

const Device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobile: `(max-width: ${size.tablet})`,
  maxtablet: `(max-width: ${size.tablet} - 1)`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
  retina: `only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)`,
};

export default Device;
