import styled from "styled-components";
import Device from "device";

const Button = styled.button`
  padding: 10px 20px;
  background: #eee;
  border: 0 solid ${(props) => props.theme.color.gray};
  font-size: 14px;
  font-weight: bold;
  margin: 15px 0 0 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 5px -15px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  user-select: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;

  @media ${Device.laptop} {
    margin: 25px 0 0 0;
    padding: 20px 40px;
  }

  &:hover {
    color: #000;
  }

  &.min {
    padding: 7px;
    font-size: 9px;

    @media ${Device.tablet} {
      font-size: 14px;
      padding: 10px 20px;
    }
    @media ${Device.laptop} {
      font-size: 12px;
      padding: 10px 20px;
    }
  }
  &.white {
    background: #fff;
    border: 1px solid #000;
    color: #000;
  }
  &.invert {
    background: ${(props) => props.theme.color.cyan};
    color: #fff;

    &:hover {
      color: #fff;
      background: ${(props) => props.theme.color.cyan_hover};
    }
  }
`;

export default Button;
