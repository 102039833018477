import React, { useContext } from "react";
import styled from "styled-components";
import Device from "device";

import { Modal } from "components/Modal/Modal";

import { ReactComponent as SvgMenu } from "assets/icons/icon_menu.svg";

import AppContext from "contexts/AppContext";

import ym from "react-yandex-metrika";

const Body = styled.section`
  padding: 0;
`;
const Close = styled.button`
  position: absolute;
  top: 16px;
  right: 20px;
  z-index: 99;
  cursor: pointer;
  transition: all 0.4s;
  user-select: none;
  background: #fff;
  border-radius: 100px;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);

  @media ${Device.laptop} {
    right: 70px;
  }
`;

const Tour = styled.iframe`
  position: relative;
  width: 100%;
  border: 0;
  height: 100%;
  min-height: 100vh;

  @media ${Device.laptop} {
    height: 600px;
  }
`;
const IconMenu = styled(SvgMenu)`
  width: 38px;
  height: 38px;
  stroke: #222;
  transform: translate(-1px, -1px);
`;

const TourModal = (props) => {
  const { setModal } = useContext(AppContext);
  ym("reachGoal", "open3dTour");

  return (
    <Modal textAlign="center" modalWidth="max" padding="0">
      <Body>
        <Tour
          src="https://yandex.ru/map-widget/v1/-/CCQdVHf31A?wmode=transparent"
          frameborder="0"
          allowFullScreen="true"
          wmode="Opaque"
        ></Tour>
        <Close onClick={() => setModal(null)} className="cross">
          <IconMenu />
        </Close>
      </Body>
    </Modal>
  );
};

export default TourModal;
