import React, { useState, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import Device from "device";
import Container from "components/Container";
import Button from "components/Button";
import TourModal from "components/Modal/TourModal";
import ReviewModal from "components/Modal/ReviewModal";

import { isWebpSupported } from "react-image-webp/dist/utils";

import Slider from "react-slick";
import photos from "content/photos";

import AppContext from "contexts/AppContext";

import ym from "react-yandex-metrika";

import { ReactComponent as SvgSectionArrow } from "assets/icons/section_arrow2.svg";
import { ReactComponent as SvgArrow } from "assets/icons/arrow_left.svg";
import { ReactComponent as Svg360 } from "assets/icons/360icon.svg";
import { ReactComponent as SvgVideo } from "assets/icons/video_icon.svg";
import { ReactComponent as SvgReview } from "assets/icons/review_icon.svg";

const Wrap = styled.section`
  width: 100%;

  background: #fafafa;
  display: flex;
  align-items: center;
  padding: 40px 0 0 0;
  position: relative;
  z-index: 3;
  flex-wrap: wrap;
  overflow: hidden;

  @media ${Device.laptop} {
    min-height: 100vh;
    flex-wrap: no-wrap;
    padding: 100px 0;
  }

  &.active {
    .instruments {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
const Title = styled.h2`
  color: ${(props) => props.theme.color.gray};
  font-weight: bold;
`;
const Text = styled.div`
  padding: 0;

  @media ${Device.laptop} {
    padding: 0 0 0 55%;
  }
`;
const Box = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin: 40px 0 0 0;

  @media ${Device.laptop} {
    position: absolute;
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    padding: 0;
    margin: 0;
    z-index: 2;
    height: auto;
  }
`;
const Desc = styled.div``;
const Instruments = styled.div`
  display: none;

  @media ${Device.laptop} {
    display: block;
    position: absolute;
    width: 300px;
    height: 300px;
    right: 50px;
    bottom: 0;
    background-image: url("i/instruments.png");
    background-size: 300px auto;
    background-repeat: none;
    opacity: 0;
    transition: all 0.4s;
    transform: translateY(100px);
  }
`;
const SectionArrow = styled(SvgSectionArrow)`
  fill: rgb(238, 239, 244);
  position: absolute;
  top: -4px;
  right: 10px;
  width: 150px;
  transform: rotate(180deg);

  @media ${Device.laptop} {
    left: 55%;
    top: -1px;
    right: auto;
    width: 200px;
  }
`;
const Gallery = styled(Slider)`
  padding: 0;
  background-color: #eee;
  height: 100%;
  width: 100%;
  position: relative;

  .slick-list,
  .slick-track {
    height: 100%;
    min-height: 100%;
  }
  .slick-arrow {
    z-index: 4;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .slick-slide {
    height: 100%;

    div {
      height: 100%;
      min-height: 100%;
    }
  }
  .slick-dots {
    bottom: 10px;
    z-index: 3;

    li {
      margin: 0 8px;
      cursor: pointer;

      @media ${Device.laptop} {
        margin: 0 10px;
      }

      button {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
        opacity: 1;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background-color: #346e96;
          box-shadow: 0 0 2px 2px rgba(255, 255, 255, 1);
        }
      }
    }
  }
`;
const GalleryItem = styled.div`
  height: 100%;
  min-height: 100px;
  position: relative;
`;
const GalleryImg = styled.div`
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const SampleNextArrow = styled(SvgArrow)``;
const SamplePrevArrow = styled(SvgArrow)``;
const CustomButton = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);
const VideoWrap = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  padding: 0;
  z-index: 99;

  @media ${Device.laptop} {
    position: absolute;
  }
`;
const Video = styled.video`
  width: 100%;
  height: 100%;
  min-height: 100%;
`;
const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  width: 200px;
  height: 200px;
  background: url("i/play_button.png");
  background-position: 50%;
  background-size: 200px auto;
  background-color: #818181;
  background-repeat: no-repeat;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  z-index: 99;
  padding: 0;
  transition: all 0.4s;
  transform: scale(1);
  border: 6px solid #fff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);

  span {
    position: absolute;
    text-align: center;
    color: #fff;
    font-weight: bold;
    left: 0;
    right: 0;
    bottom: -50px;
    font-size: 16px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: ${(props) => props.theme.color.dark};
    transform: scale(1.05);
  }
`;
const CloseVideo = styled.button`
  position: fixed;
  top: 20px;
  right: 90px;
  height: 40px;
  border-radius: 50px;
  padding: 0 20px;
  line-height: 40px;
  background: #fff;
  cursor: pointer;
  z-index: 999;
  transition: all 0.4s;
  display: none;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  border: none;
  text-transform: uppercase;

  @media ${Device.laptop} {
    line-height: 50px;
    height: 50px;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.cyan};
    color: #fff;
  }

  &.show {
    display: block;
  }
`;
const IconCss = css`
  width: 15px;
  height: 20px;
  margin: 0 5px 0 0;
  fill: currentColor;

  @media ${Device.mobileL} {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
  }
`;
const Icon360 = styled(Svg360)`
  ${IconCss}
`;
const IconVideo = styled(SvgVideo)`
  ${IconCss}
`;
const IconReview = styled(SvgReview)`
  ${IconCss}
`;
const Buttons = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 0 0;
`;
const AboutTextButton = styled(Button)`
  line-height: 20px;
  margin: 0 10px 0 0;

  @media ${Device.laptop} {
    margin: 15px 15px 0 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const About = () => {
  const { section, setModal } = useContext(AppContext);
  const [video, setVideo] = useState(false);

  // stop slider on next & prev button click
  const sl = useRef(null);
  const vd = useRef(null);
  let t;

  function handlePressBtn() {
    ym("reachGoal", "changeAboutSliderBtn");
    sl.current.slickPause();
    clearTimeout(t);

    t = setTimeout(() => {
      sl.current.slickPlay();
    }, 10000);
  }
  function handleVideoToggle(url) {
    setVideo(url);
    if (url) {
      setTimeout(() => {
        vd.current.play();
      }, 1000);
    }
  }

  const sliderSettings = {
    dots: true,
    autoplay: false,
    //autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    nextArrow: (
      <CustomButton>
        <SampleNextArrow onClick={handlePressBtn} />
      </CustomButton>
    ),
    prevArrow: (
      <CustomButton>
        <SamplePrevArrow onClick={handlePressBtn} />
      </CustomButton>
    ),
  };

  return (
    <Wrap id="about" className={section === "about" ? "active" : null}>
      <CloseVideo
        className={video ? "show" : null}
        onClick={() => handleVideoToggle(false)}
      >
        Закрыть видео
      </CloseVideo>
      <SectionArrow />
      <Container>
        <Text>
          <Title>О клинике</Title>
          <Desc>
            <p>
              &laquo;Фьюжн&raquo; от&nbsp;англ.
              &laquo;fusion&raquo;&nbsp;&mdash; слияние, объединение, сочетание.
              Именно эти термины приходят на&nbsp;ум, когда речь идёт
              о&nbsp;команде профессионалов, объединённых одной целью. Для
              достижения этой цели необходимы актуальные знания, высокий уровень
              мануальных навыков, современное оборудование и&nbsp;комфортная
              среда. Всё это&nbsp;&mdash; мы, Fusion clinic!
            </p>

            <p>Наша цель&nbsp;&mdash; ваша здоровая и&nbsp;красивая улыбка.</p>
            <p>
              Мы&nbsp;&mdash; стоматологи! И&nbsp;мы&nbsp;создали клинику,
              в&nbsp;которой захотели&nbsp;бы лечиться сами, у&nbsp;нас это
              получилось;)
            </p>
            <Buttons>
              <AboutTextButton
                onClick={() => setModal(<TourModal />)}
                className="min invert"
              >
                <Icon360 />
                Пройди 3D Тур
              </AboutTextButton>

              <AboutTextButton
                onClick={() => handleVideoToggle(true)}
                className="min invert"
              >
                <IconVideo />
                Видео о клинике
              </AboutTextButton>
              <AboutTextButton
                onClick={() => setModal(<ReviewModal />)}
                className="min"
              >
                <IconReview />
                Отзывы
              </AboutTextButton>
            </Buttons>
          </Desc>
        </Text>
      </Container>
      <Box>
        <Gallery {...sliderSettings} ref={sl}>
          {photos.map((item, index) => (
            <GalleryItem key={index}>
              <GalleryImg
                style={{
                  backgroundImage: `url(${
                    isWebpSupported() ? item.webp : item.url
                  })`,
                }}
              ></GalleryImg>
              {item.video ? (
                <PlayButton onClick={() => handleVideoToggle(item.video)}>
                  <span>Посмотреть видео</span>
                </PlayButton>
              ) : null}
            </GalleryItem>
          ))}
        </Gallery>
      </Box>
      <Instruments className="instruments" />
      {video ? (
        <VideoWrap>
          <Video
            controls="controls"
            poster="i/videoposter.png"
            onEnded={() => handleVideoToggle(false)}
            ref={vd}
          >
            <source src="video/fusion.mp4" type="video/mp4" />
            Видео не поддерживается вашим браузером.
            <a href="video/fusion.mp4">Скачайте видео</a>.
          </Video>
        </VideoWrap>
      ) : null}
    </Wrap>
  );
};

export default About;
