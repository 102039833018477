import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import theme from "theme";
import Device from "device";

import MaskedInput from "react-text-mask";

import { Modal } from "components/Modal/Modal";
import Button from "components/Button";

import AppContext from "contexts/AppContext";

import ym from "react-yandex-metrika";

import { ReactComponent as SvgLogo } from "assets/icons/logo_symbol.svg";

const Header = styled.header`
  display: flex;
  padding: 40px 40px 20px 40px;
`;
const Body = styled.section`
  padding: 20px 40px 40px 40px;
`;
const Logo = styled(SvgLogo)`
  width: 30px;
  height: 40px;
  position: absolute;
  top: -50px;
  left: 50%;
  fill: ${theme.color.dark};
  transform: translate(-50%, 0);

  @media ${Device.laptop} {
    width: 40px;
    height: 60px;
    top: -70px;
  }
`;
const Title = styled.h3`
  margin: 0;
  text-align: center;
  width: 100%;
`;
const Form = styled.form``;
const Input = styled.input`
  background: none;
  border: none;
  border-bottom: 1px solid #ccc;
`;
const FieldSet = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 20px 0;
  transition: opacity;

  &.disable {
    opacity: 0.3;
    pointer-events: none;
  }
`;
const Result = styled.div`
  display: inline-block;
  width: 100%;
  padding: 20px;
  color: #fff;
  border-radius: 2px;
  margin: 10px 0 0 0;

  &.success {
    background: ${(props) => props.theme.color.green};
  }
  &.error {
    background: ${(props) => props.theme.color.red};
  }
`;
const Loader = styled.div``;
const SubmitButton = styled(Button)`
  display: inline-block;
  width: auto;
  margin: 0 15px 0 0;

  @media ${Device.laptop} {
    margin: 0 15px 0 0;
  }
`;
const Cancel = styled(Button)`
  margin: 0 15px 0 0;

  @media ${Device.laptop} {
    margin: 0 15px 0 0;
  }
`;

const Spec = styled.p`
  color: #ccc;
  font-size: 12px;

  strong {
    color: #000;
    margin-left: 5px;
  }
`;

const FormModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statusMsg, setStatusMsg] = useState(null);
  const fieldsInitial = { name: "", phone: "" };
  const { setModal } = useContext(AppContext);

  const [fields, setFields] = useState(fieldsInitial);
  const [status, setStatus] = useState(false);

  ym("reachGoal", "openForm");

  function updFields(e) {
    setFields({
      ...fields,
      [e.target.id]: e.target.value,
    });
  }

  function serialize(data) {
    let flds = "";
    for (var item of data.entries()) {
      flds +=
        encodeURIComponent(item[0]) + "=" + encodeURIComponent(item[1]) + "&";
    }
    return flds;
  }

  const sendDdata = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    setIsLoading(true);

    axios
      .post("/mail.php", serialize(data))
      .then((response) => {
        console.log(response);
        // Handle success.
        setIsLoading(false);
        setFields(fieldsInitial);
        setStatus(true);
        setStatusMsg(
          "Спасибо, Ваша заявка принята! Мы обязательно свяжемся с Вами в ближайшее время."
        );
        setTimeout(() => setModal(null), 3000);
        ym("reachGoal", "sendFormSucces");
        setTimeout(() => (window.location = "/thank-you"), 3000); // redirect
      })
      .catch((error) => {
        // Handle error.
        setIsLoading(false);
        setStatusMsg("" + error.toString());
        console.log("An error occurred:", error);
        ym("reachGoal", "sendFormError");
      });
  };

  return (
    <Modal textAlign="center" modalWidth="min" padding="0">
      <Logo />
      <Header>
        <Title>Запись на прием</Title>
      </Header>
      <Body>
        <Form onSubmit={sendDdata}>
          <FieldSet>
            <Input
              autoFocus
              type="text"
              name="name"
              id="name"
              required
              placeholder="Имя"
              value={fields.name}
              onChange={updFields}
            />
          </FieldSet>
          <FieldSet>
            <MaskedInput
              mask={[
                "+",
                "7",
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              type="text"
              value={fields.phone}
              onChange={updFields}
              type="text"
              name="phone"
              id="phone"
              autoComplete="off"
              required
              placeholder="Телефон"
            />
          </FieldSet>
          {props.name && props.type ? (
            <FieldSet>
              <Input
                type="hidden"
                name={props.type}
                id={props.type}
                value={props.name}
              />
              <Spec>
                {(() => {
                  switch (props.type) {
                    case "service":
                      return "Услуга:";
                    case "person":
                      return "Специалист:";
                    default:
                      return "";
                  }
                })()}
                <strong>{props.name}</strong>
              </Spec>
            </FieldSet>
          ) : null}

          <FieldSet>
            <SubmitButton
              className="min invert"
              type="submit"
              //disabled={!validateForm()}
            >
              Записаться
            </SubmitButton>
            <Cancel
              type="button"
              className="min"
              onClick={() => setModal(null)}
            >
              Закрыть
            </Cancel>
          </FieldSet>
        </Form>
        {statusMsg ? (
          <Result className={status ? "success" : "error"}>{statusMsg}</Result>
        ) : null}
      </Body>
      {isLoading ? <Loader /> : null}
    </Modal>
  );
};

export default FormModal;
