import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Device from "device";
import Container from "components/Container";
import FormModal from "components/Modal/FormModal";
import ContentModal from "components/Modal/ContentModal";

import { YMaps, Map, Placemark } from "react-yandex-maps";
import { isWebpSupported } from "react-image-webp/dist/utils";

import ym from "react-yandex-metrika";

import AppContext from "contexts/AppContext";
import { ReactComponent as SvgSectionArrow } from "assets/icons/section_arrow2.svg";

const Wrap = styled.section`
  width: 100%;
  background: #fafafa;
  display: flex;
  padding: 80px 0 40px 0;
  background-size: cover;
  position: relative;
  z-index: 3;
  overflow: hidden;

  @media ${Device.laptop} {
    padding: 120px 0;
    min-height: 100vh;
  }

  &:before {
    @media ${Device.laptop} {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 150px;
      background: linear-gradient(
        to bottom,
        rgba(44, 14, 0, 0.1),
        rgba(255, 255, 255, 0)
      );
      z-index: 2;
      pointer-events: none;
    }
  }
`;
const Title = styled.h2`
  margin: 0 0 30px 0;
  font-weight: bold;
  color: ${(props) => props.theme.color.gray};

  @media ${Device.tablet} {
    margin: 0 0 20px 0;
  }
  @media ${Device.laptop} {
    margin: 0 0 50px 0;
  }
`;
const Text = styled.div`
  padding: 0;
  width: 100%;
  position: relative;

  @media ${Device.tablet} {
    padding: 0 0 0 45%;
  }
  @media ${Device.laptop} {
    padding: 0 0 0 55%;
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0 0;

  @media ${Device.laptop} {
    margin: 30px 0 0 0;
  }
`;
const Button = styled.button`
  padding: 10px 15px;
  background: rgba(255, 255, 255, 1);
  border: 0 solid ${(props) => props.theme.color.gray};
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0 0 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 5px -15px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;

  @media ${Device.laptop} {
    margin: 0 25px 0 0;
    padding: 10px 20px;
  }

  &:hover {
    color: #000;
  }
`;
const FooterNav = styled.nav`
  margin: 30px 0 0 0;
`;
const FooterNavSpan = styled.span`
  display: block;
  font-size: 12px;
  margin: 0 0 10px 0;
  cursor: pointer;
  text-decoration: underline;
`;
const FooterNavA = styled.a`
  display: block;
  font-size: 12px;
  margin: 0 0 10px 0;
  cursor: pointer;
  text-decoration: underline;
`;
const SectionArrow = styled(SvgSectionArrow)`
  display: none;

  @media ${Device.laptop} {
    display: block;
    fill: #eff9ff;
    position: absolute;
    top: -1px;
    left: 25%;
    width: 200px;
    height: 31px;
    transition: all 0.4s;
    opacity: 1;
    transform: rotate(180deg);
    z-index: 5;
  }
`;
const Btn = styled(Button)`
  margin: 0 30px 0 0;
`;

const YMapsWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.color.dark};
  color: #fff;
  box-shadow: 0 100px 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all 0.4s;
  transform: scale(0.5);
  border-right: 4px solid #fff;
  z-index: 99;
  visibilty: hidden;
  pointer-events: none;

  &.show {
    transform: scale(1);
    visibilty: visible;
    opacity: 1;
    pointer-events: all;
  }
`;
const CloseMap = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  padding: 0 20px;
  background: #fff;
  cursor: pointer;
  z-index: 999;
  transition: all 0.4s;
  display: none;
  width: 200px;
  text-align: center;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);

  @media ${Device.laptop} {
    line-height: 50px;
    height: 50px;
    right: 80px;
    left: auto;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.cyan};
    color: #fff;
  }

  &.show {
    display: block;
  }
`;

const Contacts = () => {
  const { config, section, setModal, locationParams } = useContext(AppContext);
  const [showMap, setShowMap] = useState(false);

  function handleMapToggle() {
    setShowMap(showMap ? false : true);
    ym("reachGoal", "openMap");
  }

  const coordinate = [59.9058, 30.3132];
  const mapConfig = {
    center: coordinate,
    zoom: 17,
    controls: [],
    yandexMapDisablePoiInteractivity: true,
  };

  useEffect(() => {
    const formHash = locationParams.has("form");

    if (formHash) {
      setModal(<FormModal />);
    }
  }, [locationParams, setModal]);

  return (
    <Wrap
      style={{
        backgroundImage: `url(${
          isWebpSupported() ? "/i/brush.webp" : "/i/brush.jpg"
        })`,
      }}
      id="contacts"
      className={section === "contacts" ? "active" : null}
    >
      <CloseMap
        className={showMap ? "show" : null}
        onClick={() => handleMapToggle()}
      >
        Закрыть карту
      </CloseMap>
      <SectionArrow className={showMap ? "hide" : null} />
      <Container className="align-stretch">
        <Text>
          <Title>fusionclinic.ru</Title>
          <p>
            <strong>Адрес:</strong> Московский проспект, д.73, корпус 5
          </p>
          <p>
            <strong>Телефон:</strong>{" "}
            <a
              href={`tel:${config.code}${config.phone}`}
              onClick={() => ym("reachGoal", "clickPhoneBottom")}
            >
              {config.code}&nbsp;
              <span>{config.phone}</span>
            </a>
          </p>
          <p>
            <strong>Режим работы:</strong> 09.00 - 21.00 (ежедневно)
          </p>
          <p>
            <a href="mailto:info@fusion.clinic">info@fusionclinic.ru</a>
          </p>
          <Buttons>
            <Btn onClick={() => setModal(<FormModal />)}>
              Записаться на прием
            </Btn>
            <Btn onClick={() => handleMapToggle()}>На карте</Btn>
          </Buttons>
          <FooterNav>
            <FooterNavSpan
              onClick={() =>
                setModal(<ContentModal title="Правовая информация" />)
              }
            >
              Правовая информация
            </FooterNavSpan>
            <FooterNavA href="/licence.pdf" target="_blank">
              Лицензии
            </FooterNavA>
            <FooterNavA href="/contract.pdf" target="_blank">
              Договор
            </FooterNavA>
          </FooterNav>
        </Text>
      </Container>
      <YMapsWrap className={showMap ? "show" : null}>
        <YMaps style={{ width: "100%", height: "100%", minHeight: "100%" }}>
          <Map
            defaultState={mapConfig}
            style={{ width: "100%", height: "100%", minHeight: "100%" }}
            instanceRef={(ref) => {
              ref && ref.behaviors.disable("scrollZoom");
            }}
          >
            <Placemark
              geometry={coordinate}
              properties={{
                hintContent: "Клиника Fusion",
                balloonContentHeader: "Клиника &ldquo;Fusion&rdquo;",
                balloonContentBody:
                  "<strong>Адрес:</strong> Московский проспект 73 корпус 5,<br/><strong>Телефон</strong>:  +7 812 <span>701-06-33</span><br/><strong>Режим работы</strong>: 09.00 - 21.00 (ежедневно)",
                balloonContent: "Режим работы: 09.00 - 21.00 (ежедневно)",
                //balloonContentFooter: ""
              }}
              options={{
                hideIconOnBalloonOpen: false,
                // Options. You must specify this type of layout.
                iconLayout: "default#image",
                // Custom image for the placemark icon.
                iconImageHref: "i/baloon.png",
                iconShadowHref: "i/baloon.png",
                // The size of the placemark.
                iconImageSize: [60, 60],
                iconImageOffset: [-30, 5],
                zIndex: 999,
                // The offset of the upper left corner of the icon relative
                // to its "tail" (the anchor point).
                //iconImageOffset: [-3, -30]
              }}
              modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
            />
          </Map>
        </YMaps>
      </YMapsWrap>
    </Wrap>
  );
};

export default Contacts;
