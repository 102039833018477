import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import Device from "device";
import Container from "components/Container";
import Button from "components/Button";
import FormModal from "components/Modal/FormModal";
import PortfolioModal from "components/Modal/PortfolioModal";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Keyboard, Scrollbar, Navigation } from "swiper/modules";

import { ReactComponent as SvgLogo } from "assets/icons/logo_symbol.svg";
import { ReactComponent as SvgLogoHor } from "assets/icons/logo_horizont.svg";
import { ReactComponent as SvgArrow } from "assets/icons/arrow-right.svg";
import { ReactComponent as SvgFullscreen } from "assets/icons/icon-fullscreen.svg";

import AppContext from "contexts/AppContext";

import { portfolio } from "content/portfolio";

const Wrap = styled.section`
  width: 100%;
  display: flex;
  align-items: stretch;
  padding: 20px 20px 40px;
  position: relative;
  background: #eff9ff;
  overflow: visible;
  z-index: 5;
  position: relative;

  @media ${Device.laptop} {
    padding: 100px 0 80px;
  }

  ${Container} {
    padding-top: 20px;
    z-index: 9;

    padding-left: 0;
    padding-right: 0;
  }

  .swiper-wrapper {
    padding-bottom: 10px;
    align-items: stretch;
  }
`;
const LogoCircle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: ${(props) => props.theme.color.cyan};
  z-index: 3;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.05),
    0 0 0 15px rgba(255, 255, 255, 0.05);
`;
const LogoF = styled(SvgLogo)`
  width: 30px;
  height: 30px;
  fill: #fff;
`;
const LogoWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`;
const Logo = styled(SvgLogoHor)`
  display: none;

  @media ${Device.laptop} {
    display: block;
    fill: rgba(0, 0, 0, 0.03);
    position: absolute;
    width: 2000px;
    height: 900px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    pointer-events: none;
    z-index: 5;
  }
`;
const Logo2 = styled(SvgLogo)`
  width: 500px;
  height: 900px;
  fill: rgba(0, 0, 0, 0.05);
  pointer-events: none;
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${Device.laptop} {
    display: none;
  }
`;

const PortfolioAll = styled.section`
  width: 100%;
  padding: 0;
  color: #fff;
  position: relative;
`;
const Title = styled.h2`
  width: 100%;
  font-weight: bold;
  position: relative;
  z-index: 2;
  color: #163d5c;
  margin: 0 0 20px 0;
  text-align: center;
`;
const Header = styled.header`
  display: none;

  @media ${Device.laptop} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 20px 0;
  }

  ${Title} {
    display: none;

    @media ${Device.laptop} {
      display: block;
      text-align: left;
      margin: 0;
    }
  }
`;
const PortfolioList = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
`;
const PortfolioCover = styled.div`
  width: 100%;
  height: 150px;
  background-color: #dee0ea;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0.1);
  position: relative;

  @media ${Device.laptop} {
    height: 200px;
  }
`;
const PortfolioItem = styled(SwiperSlide)`
  background: #fff;
  width: 100%;
  color: ${(props) => props.theme.color.dark};
  position: relative;
  transition: all 1s 0.1s;
  border: none;
  box-shadow: 0 20px 5px -15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  border-radius: 4px;

  @media ${Device.tablet} {
    max-width: 45%;
  }
  @media ${Device.laptop} {
    max-width: 23%;
  }

  h3 {
    text-align: center;
    padding: 20px;
    margin: 0;
    font-weight: bold;
    font-size: 16px;

    @media ${Device.laptop} {
      padding: 30px;
      font-size: 18px;
    }
  }
  &:hover {
    z-index: 3;
    opacity: 1;

    .readmore {
      opacity: 1;
      visibility: visible;
    }
  }
`;
const More = styled.div`
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 10px;
  line-height: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.4s;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  span {
    width: 100%;
    text-align: center;
  }
`;
const FullscreenIcon = styled(SvgFullscreen)`
  width: 18px;
  height: 18px;
  fill: #fff;
`;
const StyledSwiper = styled(Swiper)`
  padding: 0;
  margin: 0;
`;
const ArrowCss = css`
  position: absolute;
  top: 60px;
  z-index: 99;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background: #fff;
  border-radius: 40px;
  color: #5fa2d0;
  transition: 0.1s;
  &:hover {
    background-color: #5fa2d0;
    color: #fff;
  }

  @media ${Device.laptop} {
    top: 100px;
  }
`;
const ArrowPrev = styled.button`
  ${ArrowCss}
  left: -15px;
  transform: rotate(180deg);

  @media ${Device.laptop} {
    left: 10px;
  }
`;
const ArrowNext = styled.button`
  ${ArrowCss}
  right: -15px;

  @media ${Device.laptop} {
    right: 10px;
  }
`;
const IconArrow = styled(SvgArrow)`
  width: 40px;
  height: 40px;
  stroke: currentColor;

  polyline,
  line,
  circle {
    stroke: currentColor;
  }
`;

const Portfolio = () => {
  const { section, setModal } = useContext(AppContext);

  function isHasImage(value) {
    if (value.hasOwnProperty("items")) {
      if (value.items.length) {
        return true;
      }
    }
    return;
  }
  const portfolioWithImages = portfolio.filter(isHasImage);

  return (
    <Wrap id="portfolio" className={section === "portfolio" ? "active" : null}>
      <LogoCircle>
        <LogoF />
      </LogoCircle>
      <LogoWrap>
        <Logo />
        <Logo2 />
      </LogoWrap>
      <Container className="align-stretch full-width">
        <Title>Портфолио</Title>
        <PortfolioAll>
          <ArrowPrev className="slider-prev">
            <IconArrow />
          </ArrowPrev>
          <ArrowNext className="slider-next">
            <IconArrow />
          </ArrowNext>
          <StyledSwiper
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={0}
            loop={true}
            allowTouchMove={true}
            simulateTouch={true}
            keyboard={{
              enabled: true,
            }}
            navigation={{
              enabled: true,
              nextEl: ".slider-next",
              prevEl: ".slider-prev",
            }}
            modules={[Keyboard, Navigation]}
            className="portfolio-list"
            breakpoints={{
              640: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {portfolioWithImages.map((item, index) => (
              <PortfolioItem
                key={index}
                onClick={() => setModal(<PortfolioModal data={item} />)}
              >
                <PortfolioCover
                  style={{ backgroundImage: `url(${item.items[0].after})` }}
                >
                  <More className="readmore">
                    <FullscreenIcon />
                  </More>
                </PortfolioCover>
                <h3>{item.title}</h3>
              </PortfolioItem>
            ))}
          </StyledSwiper>
        </PortfolioAll>
      </Container>
    </Wrap>
  );
};

export default Portfolio;
