import React, { Fragment } from "react";
import ym from "react-yandex-metrika";

const Metrika = () => {
  function getScrollPercent() {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  }

  function handleScroll() {
    let sPos = Math.round(getScrollPercent());
    let sPosLocal = localStorage.getItem("scrollPosition");

    if (!sPosLocal) {
      localStorage.setItem("scrollPosition", sPos);
    } else {
      if (sPos > sPosLocal) {
        localStorage.setItem("scrollPosition", sPos);
      }
    }

    let time = Math.round(performance.now() / 1000);

    if (time > 30 && sPosLocal > 40) {
      if (!localStorage.getItem("scroll40")) {
        ym("reachGoal", "scroll40");
        localStorage.setItem("scroll40", true);
      }
    }
    if (time > 60 && sPosLocal > 70) {
      if (!localStorage.getItem("scroll70")) {
        ym("reachGoal", "scroll70");
        localStorage.setItem("scroll70", true);
      }
    }
  }

  window.addEventListener("scroll", handleScroll);

  return <Fragment />;
};

export default Metrika;
