import React, { useContext } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import theme from "theme";
import Device from "device";

import AppContext from "contexts/AppContext";

const node = document.getElementById("modal");

const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;

  z-index: 999;

  @media ${Device.laptop} {
    padding: 20px;
  }

  &.fade {
    transform: scale(0) !important;
    opacity: 0 !important;
  }
  &.max {
    padding: 0;
    transform: scale(1);
    opacity: 1;
    transition: all 0.4s;

    @media ${Device.laptop} {
      padding: 0;
    }
  }

  @media ${Device.laptop} {
    padding: 40px;
  }
`;

const MainModalWindow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  background: #fff;
  color: #000;
  padding: 0;
  margin: 0 auto;
  border-radius: 4px;
  position: relative;
  position: relative;
  margin: 0 auto;
  z-index: 3;
  box-shadow: 0 20px 5px -15px rgba(0, 0, 0, 0.1);

  @media ${Device.laptop} {
    padding: 30px;
  }

  &.flex-direction-row {
    flex-direction: row;
  }

  &.min {
    @media ${Device.laptop} {
      max-width: 500px;
    }
  }
  &.max {
    max-width: 100%;
    height: 100%;
    overflow: auto;
  }
  &.cover {
    @media ${Device.laptop} {
      padding-left: 50%;
    }
  }

  @media ${Device.laptop} {
    padding: ${(props) => props.padding || "40px"};
    max-width: 970px;
  }

  .secondary {
    color: ${theme.color.dark};
  }
`;
const ModalBackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  background-size: 100px auto;
  background-repeat: repeat;
  z-index: 2;
  cursor: pointer;
  backdrop-filter: blur(2px);

  @supports (
    (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))
  ) {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
`;

export function Modal({ bgColor, padding, textAlign, modalWidth, children }) {
  const { setModal } = useContext(AppContext);

  function handleCloseModal(event) {
    console.log("close modal..");
    setModal(null);
  }

  return createPortal(
    <ModalWrap className={modalWidth}>
      <MainModalWindow
        bgColor={bgColor}
        padding={padding}
        textAlign={textAlign}
        className={modalWidth}
      >
        {children}
      </MainModalWindow>
      <ModalBackDrop onClick={handleCloseModal} />
    </ModalWrap>,
    node
  );
}
