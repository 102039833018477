import React, { useState, useContext, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import Device from "device";
import Container from "components/Container";
import Button from "components/Button";
import FormModal from "components/Modal/FormModal";
import ContentModal from "components/Modal/ContentModal";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

import {
  Keyboard,
  Scrollbar,
  Navigation,
  Pagination,
  Mousewheel,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

import AppContext from "contexts/AppContext";
import ym from "react-yandex-metrika";

import { ReactComponent as SvgPatternElement } from "assets/icons/pattern_element.svg";
import { ReactComponent as SvgSectionArrow } from "assets/icons/section_arrow2.svg";

import { persons } from "content/persons";

const PhotoLoaderAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrap = styled.section`
  width: 100%;
  display: flex;
  align-items: stretch;
  padding: 20px 0 40px;
  overflow: hidden;
  position: relative;
  background: #fff;
  overflow: hidden;
  z-index: 0;

  @media ${Device.laptop} {
    min-height: 100vh;
    padding: 100px 0 120px;
  }

  &:before {
    @media ${Device.laptop} {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50%;
      background-image: url("i/pattern_gray.svg");
      background-color: #fff;
      background-size: 120px auto;
      background-repeat: repeat;
    }
  }
  &:after {
    @media ${Device.laptop} {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      background-image: url("i/pattern_blue_light.svg");
      background-size: 120px auto;
      background-repeat: repeat;
      background-color: ${(props) => props.theme.color.dark};
    }
  }

  ${Container} {
    padding-top: 20px;
  }
  .swiper-wrapper {
    align-items: center;
  }
`;
const All = styled.section`
  width: 100%;
  height: 100%;
  padding: 40px 0;
  color: #fff;
  position: relative;

  &:after {
    @media ${Device.mobile} {
      content: "";
      position: absolute;
      top: 0;
      left: -15px;
      right: -15px;
      bottom: 0;
      z-index: -1;
      pointer-events: none;
      background-image: url("i/pattern_blue_light.svg");
      background-size: 80px auto;
      background-repeat: repeat;
      background-color: ${(props) => props.theme.color.dark};
    }
  }

  @media ${Device.laptop} {
    max-width: 50%;
    padding: 0 0 0 40px;
    background: none;
  }
`;
const Title = styled.h2`
  width: 100%;
  font-weight: bold;
  position: relative;
  z-index: 2;

  &.left {
    text-align: center;
    @media ${Device.laptop} {
      display: none;
    }
  }
  &.right {
    display: none;
    @media ${Device.laptop} {
      display: block;
    }
  }
`;
const Person = styled.div`
  width: 100%;
  padding: 0 0 40px 0;

  @media ${Device.laptop} {
    max-width: 50%;
    padding: 0 40px 0 0;
  }
`;
const PhotoWrap = styled.div`
  position: relative;
  margin: 0 0 40px 0;
  cursor: pointer;
`;
const PhotoLoader = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 4px solid rgba(255, 255, 255, 0);
  border-top: 4px solid rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  animation-name: ${PhotoLoaderAnimation};
  animation-delay: 0s;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  z-index: 1;
`;
const Photo = styled.div`
  max-width: 100%;
  z-index: 2;
  margin: 0;
  position: relative;
  border-radius: 30px 4px 30px 4px;
  width: 100%;
  height: 350px;
  background-size: cover;
  background-position: 50%;
  z-index: 2;

  @media ${Device.tablet} {
    height: 600px;
  }
  @media ${Device.laptop} {
    border-radius: 80px 10px 80px 10px;
    height: 400px;
  }
`;
const PatternElement = styled(SvgPatternElement)`
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 3;
  fill: #fff;
  opacity: 0.3;
`;
const PatternElementTop = styled(PatternElement)`
  top: 20px;
  left: 20px;
  transform: rotate(180deg);
`;
const PatternElementBottom = styled(PatternElement)`
  right: 20px;
  bottom: 20px;
`;
const Name = styled.h3`
  font-size: 21px;
  margin: 0 0 10px 0;
  color: ${(props) => props.theme.color.dark};

  @media ${Device.laptop} {
    font-size: 36px;
  }
`;
const Position = styled.div`
  color: ${(props) => props.theme.color.gray};
  margin: 0 0 20px 0;
`;
const About = styled.div``;
const List = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
`;
const ItemWrap = styled.div`
  padding: 0;
  margin: 0 15px 0 0;
  display: flex;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  overflow: hidden;
`;
const Item = styled.div`
  margin: 0 0 15px 0;
  width: 100%;
  cursor: pointer;
  padding: 0;

  @media ${Device.tablet} {
    max-width: 50%;
  }

  &.active {
    ${ItemWrap} {
      background: #5fa2d0;
    }
    img {
      filter: grayscale(0%);
    }
  }
`;
const Thumb = styled.img`
  width: 80px;
  height: 80px;
  margin: 0;
  filter: grayscale(70%);
  transition: all 0.4s;
  object-fit: cover;

  @media ${Device.tablet} {
    width: 100px;
    height: 140px;
  }
`;
const ItemAbout = styled.div`
  padding: 10px 15px;
`;
const ThumbName = styled.h3`
  font-size: 14px;
  margin: 0;
  color: #fff;
`;
const ThumbPosition = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0 0 0;
  color: #fff;
`;
const SectionArrow = styled(SvgSectionArrow)`
  display: none;

  @media ${Device.laptop} {
    display: block;
    fill: #fafafa;
    position: absolute;
    top: -1px;
    left: 55%;
    width: 200px;
    height: 31px;
    transform: rotate(180deg);
    z-index: 9;
  }
`;
const Btns = styled.div`
  display: flex;
`;
const More = styled(Button)`
  margin: 0 15px 0 0;
`;
const Appointment = styled(Button)`
  width: auto;
  flex: 1;
  margin: 0;
`;
export const StyledSwiper = styled(Swiper)`
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
`;
export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  max-width: 100px;
  padding: 10px;
  user-select: none;
`;
const CertImg = styled(LazyLoadImage)`
  width: 100%;
  max-width: 100px;
  border-radius: 4px;
  cursor: pointer;
`;

const Team = () => {
  const { setModal } = useContext(AppContext);
  const [aboutPerson, setAboutPerson] = useState(persons[0]);
  const [currentPerson, setCurrentPerson] = useState(0);
  const toperson = useRef(null);

  function scrollToRef(ref) {
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  }
  function handlePersonChange(id) {
    if (id === currentPerson) {
      return;
    }
    scrollToRef(toperson);
    setAboutPerson(persons[id]);
    setCurrentPerson(id);
    ym("reachGoal", "personCardView");
  }
  function handlePersonForm() {
    setModal(<FormModal type="person" name={persons[currentPerson].name} />);
  }

  useEffect(() => {
    // console.log(aboutPerson);
  }, []);

  return (
    <Wrap id="team">
      <SectionArrow />
      <Container className="align-stretch" ref={toperson}>
        <Title className="left">Наша команда</Title>
        <Person>
          <PhotoWrap
            onClick={() =>
              setModal(
                <ContentModal
                  cover={aboutPerson.photo}
                  title={aboutPerson.name}
                  about={aboutPerson.about}
                  content={aboutPerson.abouttext}
                  type="person"
                />
              )
            }
          >
            <PatternElementTop />
            <LazyLoadComponent id={"mainphoto"}>
              <Photo style={{ backgroundImage: `url(${aboutPerson.photo})` }} />
            </LazyLoadComponent>
            <PatternElementBottom />
            <PhotoLoader />
          </PhotoWrap>
          <Name>{aboutPerson.name}</Name>
          <Position>{aboutPerson.position}</Position>
          <About>{aboutPerson.about}</About>
          {aboutPerson.certificate ? (
            <LightGallery
              speed={500}
              download={false}
              plugins={[]}
              mode="lg-fade"
              selector=".certificate-item"
            >
              <StyledSwiper
                freeMode={true}
                slidesPerView={"auto"}
                centeredSlides={false}
                spaceBetween={15}
                keyboard={{
                  enabled: true,
                }}
                scrollbar={{
                  enabled: true,
                  draggable: true,
                  dragSize: 128,
                }}
                mousewheel={true}
                modules={[
                  Keyboard,
                  Mousewheel,
                  Scrollbar,
                  Navigation,
                  Pagination,
                ]}
                className="certificate"
              >
                {aboutPerson.certificate.map((item, index) => (
                  <StyledSwiperSlide key={index}>
                    <div
                      className="certificate-item"
                      data-src={item.url}
                      group="group"
                    >
                      <CertImg
                        src={item.url}
                        alt={item.title ? item.title : null}
                      />
                    </div>
                  </StyledSwiperSlide>
                ))}
              </StyledSwiper>
            </LightGallery>
          ) : null}
          <Btns>
            {aboutPerson.abouttext ? (
              <More
                onClick={() =>
                  setModal(
                    <ContentModal
                      cover={aboutPerson.photo}
                      title={aboutPerson.name}
                      about={aboutPerson.about}
                      content={aboutPerson.abouttext}
                      type="person"
                    />
                  )
                }
              >
                Подробнее..
              </More>
            ) : null}
            <Appointment className="invert" onClick={() => handlePersonForm()}>
              Записаться к этому специалисту
            </Appointment>
          </Btns>
        </Person>
        <All>
          <Title className="right">Наша команда</Title>
          <List>
            {persons.map((person, index) => (
              <Item
                key={index}
                onClick={() => handlePersonChange(index)}
                className={currentPerson === index ? "active" : ""}
              >
                <ItemWrap>
                  <LazyLoadComponent id={"thumb-" + index}>
                    <Thumb src={person.photo} alt={person.name} />
                  </LazyLoadComponent>

                  <ItemAbout>
                    <ThumbName>{person.name}</ThumbName>
                    <ThumbPosition>{person.position}</ThumbPosition>
                  </ItemAbout>
                </ItemWrap>
              </Item>
            ))}
          </List>
        </All>
      </Container>
    </Wrap>
  );
};

export default Team;
